import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/GoogleButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/lib/images/logoregister.png");
;
import(/* webpackMode: "eager" */ "/app/src/lib/images/reverbslogologin.png");
;
import(/* webpackMode: "eager" */ "/app/src/lib/images/Zestrelasbg.png");
;
import(/* webpackMode: "eager" */ "/app/src/lib/images/Zrevbg.png");
;
import(/* webpackMode: "eager" */ "/app/src/lib/images/Zwavesbg.png");
